html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}


html {
	-webkit-text-size-adjust: 100%;
}

body {
	background-color: $color-bg;
	
	@include font-size($font-size);
	font-family: $font-regular;
	color: $color-text;
}


/***
 *      /$$$$$$  /$$$$$$$$ /$$   /$$ /$$$$$$$$ /$$$$$$$   /$$$$$$  /$$      
 *     /$$__  $$| $$_____/| $$$ | $$| $$_____/| $$__  $$ /$$__  $$| $$      
 *    | $$  \__/| $$      | $$$$| $$| $$      | $$  \ $$| $$  \ $$| $$      
 *    | $$ /$$$$| $$$$$   | $$ $$ $$| $$$$$   | $$$$$$$/| $$$$$$$$| $$      
 *    | $$|_  $$| $$__/   | $$  $$$$| $$__/   | $$__  $$| $$__  $$| $$      
 *    | $$  \ $$| $$      | $$\  $$$| $$      | $$  \ $$| $$  | $$| $$      
 *    |  $$$$$$/| $$$$$$$$| $$ \  $$| $$$$$$$$| $$  | $$| $$  | $$| $$$$$$$$
 *     \______/ |________/|__/  \__/|________/|__/  |__/|__/  |__/|________/
 *                                                                          
 *                                                                          
 *                                                                          
 */

.wrapper{
	position: relative;
	width: 100%;
	@include clearfix();

	@include mq($from: tablet) {
		width: 760px;
		margin: 0 auto;	
	}

	@include mq($from: desktop) {
		width: 960px;
	}

	@include mq($from: wide) {
		width: 1200px;
	}
}

.btn-lien{
	display: block;
	width: 100%;
	margin-top: 10px;
	padding: 10px;
	text-transform: uppercase;
	font-family: $font-black;
	text-align: center;
	@include font-size(12);

	transition : background-color 0.3s ease-in-out;

	@include mq($from: tablet) {
		font-family: $font-light;
	}

	@include mq($from: desktop) {
		font-family: $font-black;
	}
}

 /***
 *               /$$ /$$       /$$                    
 *              | $$|__/      | $$                    
 *      /$$$$$$$| $$ /$$  /$$$$$$$  /$$$$$$   /$$$$$$ 
 *     /$$_____/| $$| $$ /$$__  $$ /$$__  $$ /$$__  $$
 *    |  $$$$$$ | $$| $$| $$  | $$| $$$$$$$$| $$  \__/
 *     \____  $$| $$| $$| $$  | $$| $$_____/| $$      
 *     /$$$$$$$/| $$| $$|  $$$$$$$|  $$$$$$$| $$      
 *    |_______/ |__/|__/ \_______/ \_______/|__/      
 *                                                    
 *                                                    
 *                                                    
 */

.slider{
	position: relative;
	width: 100%;
	margin: 20px 0;
	
	@include mq($from: desktop) {
		margin: 0 0 20px 0;
	}
}

.partie-slider {
	position: relative;
	margin : 0 0 15px 0;

	.text-slider{
		width: 300px;
		padding: 20px;
		position: absolute;
		top: 30%;
		left: 50%;
		margin-left: -150px;

		background-color: rgba($blue, 0.4);
		text-align: center;
		z-index: 2;

		.p-slider{
			margin: 0;
			font-family: $font-light;
			color: $white;
			text-transform: uppercase;
			@include font-size(15);
			text-shadow:1px 1px 1px rgba($black,0.5);

			@include mq($from: tablet) {
				@include font-size(20);
			}
		}
	}
}


 /***
 *      /$$$$$$              /$$                                                          
 *     /$$__  $$            | $$                                                          
 *    | $$  \__/  /$$$$$$  /$$$$$$    /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$/$$$$   /$$$$$$ 
 *    | $$$$     /$$__  $$|_  $$_/   /$$__  $$ /$$__  $$ |____  $$| $$_  $$_  $$ |____  $$
 *    | $$_/    | $$  \ $$  | $$    | $$  \ $$| $$  \__/  /$$$$$$$| $$ \ $$ \ $$  /$$$$$$$
 *    | $$      | $$  | $$  | $$ /$$| $$  | $$| $$       /$$__  $$| $$ | $$ | $$ /$$__  $$
 *    | $$      |  $$$$$$/  |  $$$$/|  $$$$$$/| $$      |  $$$$$$$| $$ | $$ | $$|  $$$$$$$
 *    |__/       \______/    \___/   \______/ |__/       \_______/|__/ |__/ |__/ \_______/
 *                                                                                        
 *                                                                                        
 *                                                                                        
 */

.icon_fotorama {
	width: 38px;
	height: 38px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	opacity: 0;

	@include mq($from: desktop) {
		opacity: 0.7;
		transition:opacity 0.3s ease-in-out;

		&:hover, &.selected{
			opacity: 1;
		}
	}
}

.fotorama__arr--next {
	@extend .icon_fotorama;
	margin-right: 10px;
	background: $white url('#{$image-path}fleche-suiv.png') no-repeat center center;

	@include retina {
		background-image: url('#{$image-path}fleche-suiv@2x.png');
		-webkit-background-size: 9px 15px;
		background-size: 9px 15px;
	}
}

.fotorama__arr--prev{
	@extend .icon_fotorama;
	margin-left: 10px;
	background: $white url('#{$image-path}fleche-prec.png') no-repeat center center;

	@include retina {
		background-image: url('#{$image-path}fleche-prec@2x.png');
		-webkit-background-size: 9px 15px;
		background-size: 9px 15px;
	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */

.icon-link:before, .icon-cloud-download:before{
	padding-right: 8px;
	vertical-align: middle;
	
	@include font-size(15);
	@include mq($from: desktop) {
		@include font-size(20);
	}
}

.download--all{

	transition: background-color 0.2s ease-in-out !important;
	z-index:1;
	color: #293581;
	display: inline-block;
	background-color: $color-1;
	padding:5px 10px;

	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		background-color: darken($color-1,15%);
	}

}

/***
 *     /$$   /$$ /$$$$$$$$  /$$$$$$  /$$$$$$$  /$$$$$$$$ /$$$$$$$ 
 *    | $$  | $$| $$_____/ /$$__  $$| $$__  $$| $$_____/| $$__  $$
 *    | $$  | $$| $$      | $$  \ $$| $$  \ $$| $$      | $$  \ $$
 *    | $$$$$$$$| $$$$$   | $$$$$$$$| $$  | $$| $$$$$   | $$$$$$$/
 *    | $$__  $$| $$__/   | $$__  $$| $$  | $$| $$__/   | $$__  $$
 *    | $$  | $$| $$      | $$  | $$| $$  | $$| $$      | $$  \ $$
 *    | $$  | $$| $$$$$$$$| $$  | $$| $$$$$$$/| $$$$$$$$| $$  | $$
 *    |__/  |__/|________/|__/  |__/|_______/ |________/|__/  |__/
 */

header{
	background-color: $blue;
	border-bottom: 2px solid $white;
}

 /***
 *     /$$                              
 *    | $$                              
 *    | $$  /$$$$$$   /$$$$$$   /$$$$$$ 
 *    | $$ /$$__  $$ /$$__  $$ /$$__  $$
 *    | $$| $$  \ $$| $$  \ $$| $$  \ $$
 *    | $$| $$  | $$| $$  | $$| $$  | $$
 *    | $$|  $$$$$$/|  $$$$$$$|  $$$$$$/
 *    |__/ \______/  \____  $$ \______/ 
 *                   /$$  \ $$          
 *                  |  $$$$$$/          
 *                   \______/           
 */

 .contain-logo{
 	position: relative;
 	height: 140px;
 	width: 100%;
 	border-top: $color-1 solid 6px;
 	background-color: $color-1;
 	margin-bottom: 5px;
	
	@include mq($from: tablet) {
		margin-bottom: 0;
	}

 	@include mq($from: desktop) {
 		width: 220px;
 		height: 155px;
 	}
 }

.logo-renaissance{
	position: relative;
	display: block;
	margin: 0 auto;
	width: 195px;
	height: 140px;
	background: transparent url('#{$image-path}logo_fim.png') no-repeat center center;
	background-size: 103px 127px;
	background-position: center;
	text-indent: -9999px;
	z-index: 2;
	outline: 0;

	@include retina {
		background-image: url('#{$image-path}logo_fim@2x.png');
		-webkit-background-size: 195px 89px;
		background-size: 118px 128px;
	}
}

.accroche{
	position: relative;
	padding-top: 20px;
	font-family: $font-regular;
	text-transform: uppercase;
	@include font-size(11);
	text-align: center;
	color: $white;

	@include mq($from: desktop) {
		@include font-size(13);
	}
}

/***
 *                                                                                                                  
 *    /$$$$$$   /$$$$$$$  /$$$$$$$  /$$$$$$   /$$$$$$$        /$$$$$$   /$$$$$$   /$$$$$$   /$$$$$$$  /$$$$$$$    
 *   |____  $$ /$$_____/ /$$_____/ /$$__  $$ /$$_____/       /$$__  $$ /$$__  $$ /$$__  $$ /$$_____/ /$$_____/    
 *    /$$$$$$$| $$      | $$      | $$$$$$$$|  $$$$$$       | $$  \ $$| $$  \__/| $$$$$$$$|  $$$$$$ | $$          
 *   /$$__  $$| $$      | $$      | $$_____/ \____  $$      | $$  | $$| $$      | $$_____/ \____  $$| $$          
 *  |  $$$$$$$|  $$$$$$$|  $$$$$$$|  $$$$$$$ /$$$$$$$/      | $$$$$$$/| $$      |  $$$$$$$ /$$$$$$$/|  $$$$$$$
 *   \_______/ \_______/ \_______/ \_______/|_______/       | $$____/ |__/       \_______/|_______/  \_______/
 *                                                          | $$                                                  
 *                                                          | $$                                                  
 *                                                          |__/                                                  
 */

#prescripteur-acces{
	display: none;
}

.prescripteur-menu{

	@include clearfix;
	
	a{
		display: block;
		float:left;
		width:50%;
		height: 26px;
		line-height: 26px;
		padding: 0 10px;
		

		@include font-size(11);
		text-align: center;
		text-transform: uppercase;

		color:#e44f36;
		background-color: transparent;
		outline: 2px solid $white;

		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		
		.no-touch & {
			&:hover {
				background-color: #e44f36;
				color: $white;
				outline: none;
			}
		}

		&.prescripteur-acces{
			background-color: $white;
			color:$blue;
			.no-touch & {
				&:hover {
					background-color: darken($blue, 10%);
				}
			}

		}

	}

	@include mq($from: desktop) {
		position: absolute;
		top: 0;
		right: 0;
		width:100%;
		text-align: right;

		li{
			display:inline;
		}

		a{
			float:none;
			display: inline-block;
			width: auto;
			height: 37px;
			line-height: 35px;
			padding: 0 15px;
			margin: 20px;
			@include font-size(17);
		}
	}

}

.prescripteur-connect {
	padding: 20px;
	background-color: $blue;
	color:$white;

	h1{
		text-align:center;
		@include font-size(25);
		margin-bottom: 30px;
	}

	form {

		ul{
			@include clearfix;
			width:100%;
			max-width:350px;
			li{
				@include clearfix;
				display: block;
				margin:0 0 5px 0;

				@include mq($from: wide) {
					float:left;
					margin-right:5px;
					width:auto;
				}

			}

		}

		label {
			color: $white;
			min-width:120px;

			@include mq($from: desktop) {
				float:left;
			}

		}

		input {
			@include font-size(11);
			height: 22px;
			padding: 0 5px;
			color: $color-3;
			font-family: $font-base;
			width: 100%;
			border: 1px solid $white;

			@include mq($from: desktop) {
				/*width:160px;*/
			}
		}

		button {
			border-radius: none;
			background-color: transparent;
			border: 1px solid $white;
			width: 160px;
			height: 22px;
			line-height: 22px;
			padding: 0 10px;
			// margin-left: 106px;

			font-family: $font-base;
			text-transform: uppercase;
			@include font-size(12);
			color: $white;

			transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

			.no-touch & {
				&:hover {
					background-color: darken($color-11,10%);
					color: $white;
				}
			}

			@include mq($from: desktop) {
				/*margin: 17px 0 0 120px;*/
			}

			@include mq($from: tablet) {
				width: 100px;
			}

			@include mq($from: desktop) {
				margin-top:0;
				width: 160px;
			}
		}
	}

	a{
		color: $white;
		text-decoration: underline;
	}
}



/***
 *                                                
 *                                                
 *     /$$$$$$/$$$$   /$$$$$$  /$$$$$$$  /$$   /$$
 *    | $$_  $$_  $$ /$$__  $$| $$__  $$| $$  | $$
 *    | $$ \ $$ \ $$| $$$$$$$$| $$  \ $$| $$  | $$
 *    | $$ | $$ | $$| $$_____/| $$  | $$| $$  | $$
 *    | $$ | $$ | $$|  $$$$$$$| $$  | $$|  $$$$$$/
 *    |__/ |__/ |__/ \_______/|__/  |__/ \______/ 
 *                                                
 *                                                
 *                                                
 */

.contain-menu{
	position: relative;

	@include mq($from: desktop) {
		position: absolute;
		top: 79px;
		right: 0;
	}

	.li-prem-niv{
		position: relative;
		width: 100%;

		@include mq($from: tablet) {
			display: table;
			float: left;
			width: 20%;
		}

		@include mq($from: desktop) {
			display: inline-block;
			// float: left;
			width: inherit;
			margin-right: 15px;

			&:last-of-type{
				margin-right: 10px;
			}
		}

		&.li-prem-niv--home {
			@include mq(tablet, desktop) {
				display: none;
			}
		}

		.a-prem-niv{
			display: block;
			padding: 10px;
			font-family: $font-light;
			@include font-size(12);
			color: $white;
			text-transform: uppercase;
			border-top: $color-1 solid 1px;

			transition:color 0.3s ease-in-out;

			&:hover, &.selected{
				color: $white;
				font-family: $font-medium;
			}

			@include mq($from: tablet) {
				border-top: none;
				text-align: center;
				display: table-cell;
				height: 40px;
				padding: 0;
			}

			@include mq(tablet, desktop) {
				vertical-align: middle;
			}

			@include mq($from: desktop){
				display: block;
				height: inherit;
				padding: 5px;
				text-align: left;
				@include font-size(13);
			}

			@include mq($from: wide) {
				padding: 10px;
				@include font-size(14);
			}
		}
	}
}

/***
 *                                                                                                
 *                                                                                                
 *      /$$$$$$$  /$$$$$$  /$$   /$$  /$$$$$$$         /$$$$$$/$$$$   /$$$$$$  /$$$$$$$  /$$   /$$
 *     /$$_____/ /$$__  $$| $$  | $$ /$$_____/ /$$$$$$| $$_  $$_  $$ /$$__  $$| $$__  $$| $$  | $$
 *    |  $$$$$$ | $$  \ $$| $$  | $$|  $$$$$$ |______/| $$ \ $$ \ $$| $$$$$$$$| $$  \ $$| $$  | $$
 *     \____  $$| $$  | $$| $$  | $$ \____  $$        | $$ | $$ | $$| $$_____/| $$  | $$| $$  | $$
 *     /$$$$$$$/|  $$$$$$/|  $$$$$$/ /$$$$$$$/        | $$ | $$ | $$|  $$$$$$$| $$  | $$|  $$$$$$/
 *    |_______/  \______/  \______/ |_______/         |__/ |__/ |__/ \_______/|__/  |__/ \______/ 
 *                                                                                                
 *                                                                                                
 *                                                                                                
 */

.ss-menu{
	position: relative;
	width: 100%;
	border-top: 1px solid $color-1;
	background-color: $color-7;

	@include mq($from: tablet) {
		position: absolute;
		left: 0;
		top: 40px;
		z-index: 200;
		width: 250px;

		visibility: hidden;
		opacity: 0;
	}

	@include mq($from: desktop) {
		top: inherit;
	}

	.li-deux-niv {
		position: relative;
		width: 100%;

		@include mq($from: tablet) {
			// padding: 10px 10px 0;

			// &:last-of-type{
			// 	padding-bottom: 10px;
			// }
		}

		.a-deux-niv{
			display: block;
			width: 100%;
			color: $color-5;
			text-transform: uppercase;
			font-family: $font-medium;
			@include font-size(10);
			//border-top: solid 1px $color-6;
			padding: 8px 20px;
			@include clearfix();
			position: relative;

			@include mq($until: tablet) {
				&:before {
					display: block;
					position: absolute;
					top: 10px;
					left: 10px;

					font-family: 'renaissance';
					content: "\e600";
				}
			}

			@include mq($from: tablet) {
				//border: solid 1px $color-1;
				padding: 10px;
			}

			transition:color 0.3s ease-in-out, background-color 0.3s ease-in-out;

			&:hover, &.selected{
				color: $white;
				background-color: $color-4;
			}

			.miniature-menu{
				float: left;
				margin-right: 10px;

				@include mq($until: tablet) {
					display: none;
				}
			}

			.intitule{
				@include mq($from: tablet) {
					float: left;
					width: 160px;
				}
			}

			.type-prog{
				display:inline-block;
				margin-top: 1px;
				padding: 1px;
				background-color: $color-11;
				color: $white;
			}
		}

	}
}

.li-prem-niv:hover .ss-menu{

	@include mq($from: tablet){
		opacity: 1;
		visibility: visible;
	}
}

/***
 *     /$$   /$$  /$$$$$$  /$$      /$$ /$$$$$$$$
 *    | $$  | $$ /$$__  $$| $$$    /$$$| $$_____/
 *    | $$  | $$| $$  \ $$| $$$$  /$$$$| $$      
 *    | $$$$$$$$| $$  | $$| $$ $$/$$ $$| $$$$$   
 *    | $$__  $$| $$  | $$| $$  $$$| $$| $$__/   
 *    | $$  | $$| $$  | $$| $$\  $ | $$| $$      
 *    | $$  | $$|  $$$$$$/| $$ \/  | $$| $$$$$$$$
 *    |__/  |__/ \______/ |__/     |__/|________/
 *                                               
 *                                               
 *                                               
 */


 /***
 *               /$$ /$$       /$$                    
 *              | $$|__/      | $$                    
 *      /$$$$$$$| $$ /$$  /$$$$$$$  /$$$$$$   /$$$$$$ 
 *     /$$_____/| $$| $$ /$$__  $$ /$$__  $$ /$$__  $$
 *    |  $$$$$$ | $$| $$| $$  | $$| $$$$$$$$| $$  \__/
 *     \____  $$| $$| $$| $$  | $$| $$_____/| $$      
 *     /$$$$$$$/| $$| $$|  $$$$$$$|  $$$$$$$| $$      
 *    |_______/ |__/|__/ \_______/ \_______/|__/      
 *                                                    
 *                                                    
 *                                                    
 */

.slide{
	// width: 100%;
	// height: 100%;

	.info-slider{
		width: 100%;
		height: 100%;
		padding: 10px;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba($blue, 0.4);

		@include mq($from: desktop) {
			width: 500px;
			height: auto;
			min-height: 150px;
			top: 50%;
			left: 50%;
			padding: 20px;
			margin: -115px 0 0 -250px; 

			text-align: center;
		}

		// @include mq($from: wide) {
		// 	padding: 20px;
		// 	width: 500px;
		// 	margin: -90px 0 0 -250px;
		// }

		.titre-slider{
			margin: 0;
			font-family: $font-light;
			color: $white;
			text-transform: uppercase;
			@include font-size(25);
			text-shadow:2px 2px 8px $black;

			@include mq($from: tablet) {
				@include font-size(40);
			}
		}

		.ss-titre-slide{
			margin: 0;
			font-family: $font-light;
			color: $white;
			text-transform: uppercase;
			@include font-size(18);
			text-shadow:2px 2px 8px $black;

			@include mq($from: tablet) {
				@include font-size(24);
			}
		}

		.descr-slider{
			font-family: $font-base;
			color: $white;
			@include font-size(15);
			text-shadow:1px 1px 1px rgba($black,0.5);
			// padding-bottom: 10px;
			display: none;

			@include mq($from: tablet) {
				display: block;
			}
		}

		.event-slider {
			display: none;

			@include mq($from: desktop) {
				display: inline-block;
				padding: 5px 0;

				border: 1px solid #fff;
				border-color: rgba(#fff, 0.8);
				border-left-width: 0;
				border-right-width: 0;

				font-family: $font-base;
				color: $white;
				@include font-size(15);
				text-shadow: 1px 1px 1px rgba($black,0.5);
			}
			
		}

		.type-slider{
			display: none;

			@include mq($from: tablet) {
				position: relative;
				display: block;
				width: 250px;
				margin-bottom: 10px;
				padding: 8px;
				background-color: $color-11;
				color: $white;
				font-family: $font-light;
				@include font-size(12);
			}

			@include mq($from: desktop) {
				margin: 0 auto 10px;
			}
		}

		.lien-slider{
			display: inline-block;
			padding: 7px 30px;
			margin-top: 10px;
			text-transform: uppercase;
			font-family: $font-medium;
			background-color: $white;
			color: $blue;
			@include font-size(12);

			.no-touch & {
				transition:color 0.2s ease-in-out, background-color 0.2s ease-in-out;
				
				&.anim:hover{
					background-color: $blue;
					color: $white;
				}
			}

			@include mq($from: tablet) {
				margin-top: 5px;
				@include font-size(13);
			}
		}
	}
}

/***
 *                           /$$                        
 *                          | $$                        
 *      /$$$$$$   /$$$$$$$ /$$$$$$   /$$   /$$  /$$$$$$$
 *     |____  $$ /$$_____/|_  $$_/  | $$  | $$ /$$_____/
 *      /$$$$$$$| $$        | $$    | $$  | $$|  $$$$$$ 
 *     /$$__  $$| $$        | $$ /$$| $$  | $$ \____  $$
 *    |  $$$$$$$|  $$$$$$$  |  $$$$/|  $$$$$$/ /$$$$$$$/
 *     \_______/ \_______/   \___/   \______/ |_______/ 
 *                                                      
 *                                                      
 *                                                      
 */

.actualites{
	position: relative;
	width: 100%;
	margin-bottom: 20px;
}

.actu{
	position: relative;
	width: 100%;
	@include clearfix();
	margin-bottom: 10px;

	@include mq($from: tablet) {
		margin-bottom: 0;
	}

	&:not(:last-of-type)  {
		@include mq($from: tablet) {
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid lighten($grey, 10%);
		}
	}
	
	.img-actu{
		width: 100%;
		
		@include mq($from: tablet) {
			float: left;
			width: 50%;
			height: 220px; // revoir
		}

		@include mq($from: desktop) {
			height: 285px; // revoir
		}

		@include mq($from: wide) {
			height: 320px; // revoir
		}

		img{
			width: 100%;
			height: 100%;
		}
	}

	.info-actu{
		width: 100%;
		padding: 10px;

		@include mq($from: tablet) {
			float: left;
			width: 50%;
		}

		@include mq($from: desktop) {
			padding: 10px 20px;
		}

		@include mq($from: wide) {
			padding: 70px 20px 20px 20px;
		}

		.type-actu{
			padding: 0 0 10px 20px;
			display: inline-block;
			font-family: $font-medium;
			color: $pink;
			text-transform: uppercase;
			@include font-size(12);

			@include mq($from: desktop) {
				@include font-size(14);
			}
		}

		.titre-actu{
			margin: 5px 0 0 0;
			clear: both;
			font-family: $font-black;
			text-transform: uppercase;
			@include font-size(13);
			color: darken($color-3, 10%);

			@include mq($from: tablet) {

				@include font-size(15);
			}

			@include mq($from: desktop) {
				@include font-size(20);
				font-family: $font-medium;
			}

			span.fleche{
				display: block;
				width: 20px;
				float: left;
				position: relative;
				top: -1px;

				font-family: $font-regular;

				font-size: 13px !important;

				@include mq($from: tablet) {

					top: 0;
				}

				@include mq($from: desktop) {
					top: 2px;
					@include font-size(20);
				}
			}

			span.contenu-titre{
				display: block;
				padding-left: 20px;
			}
		}

		.descr-actu{
			padding: 10px 0 10px 20px;
			font-family: $font-base;
			color: $color-3;
			@include font-size(12);

			@include mq($from: desktop) {
				@include font-size(14);
			}
		}

		.lien-actu{
			display: inline-block;
			width: 100%;
			padding: 7px 30px;
			text-transform: uppercase;
			font-family: $font-medium;
			background-color: lighten($color-3, 40%);
			color: $blue;
			text-align: center;
			@include font-size(12);
			transition:background-color 0.3s ease-in-out;

			&:hover, &.selected{
				background-color: lighten($color-3, 10%);
				color: $white;
			}

			@include mq($from: tablet) {
				/*width: initial;*/
				width: 100%;
				margin-left: 20px;
				text-align: left;
			}
		}
	}
}

.actu:nth-child(even){
	.img-actu, .info-actu{
		@include mq($from: tablet) {
			float: right;
		}
	}

}

/***
 *      /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$ /$$$$$$$$ /$$$$$$$$ /$$$$$$$$
 *     /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/| $$_____/|__  $$__/| $$_____/
 *    | $$  \__/| $$  \ $$| $$  \__/  | $$  | $$         | $$   | $$      
 *    |  $$$$$$ | $$  | $$| $$        | $$  | $$$$$      | $$   | $$$$$   
 *     \____  $$| $$  | $$| $$        | $$  | $$__/      | $$   | $$__/   
 *     /$$  \ $$| $$  | $$| $$    $$  | $$  | $$         | $$   | $$      
 *    |  $$$$$$/|  $$$$$$/|  $$$$$$/ /$$$$$$| $$$$$$$$   | $$   | $$$$$$$$
 *     \______/  \______/  \______/ |______/|________/   |__/   |________/
 *                                                                        
 *                                                                        
 *                                                                        
 */

 .en-tete{
 	position: relative;
 	width: 100%;
 	background-color: $color-1;
 	@include clearfix();
	margin-top: 20px;

	@include mq($from: tablet) {
		height: 100px;
	}

 	@include mq($from: desktop) {
 		margin-top: 0;
 	}

 	.titre-accroche{
 		display: table;
 		width: 100%;
 		height: 100%;
 		text-align: center;
 		font-family: $font-light;
 		@include font-size(25);
 		color: $blue;
 		text-transform: uppercase;
 		padding: 15px 0;

 		.titre-h1{
 			display: table-cell;
 			vertical-align: middle;
 		}

 		@include mq($from: tablet) {
 			float: left;
 			width: 200px;
 			padding: 0;
 			@include font-size(20);
 		}

		@include mq($from: desktop) {
			width: 350px;
			@include font-size(25);
		}

		@include mq($from: wide) {
 			width: 420px;
 			@include font-size(30);
 		}
 	}

	.accroche-entete{
		width: 100%;
		min-height: 100px;
		background: transparent url('#{$image-path}griffe-entete.png') no-repeat center left;
		padding: 30px 10px 10px 10px;
		@include clearfix();

		@include retina {
			background-image: url('#{$image-path}griffe-entete@2x.png');
			-webkit-background-size: 131px 135px;
			background-size: 131px 135px;
		}
		

		@include mq($from: tablet) {
			float: left;
			width: 330px;
			height: 100%;
		}

		@include mq($from: desktop) {
			width: 380px;
		}

		@include mq($from: wide) {
 			width: 550px;
 		}

 		.phrase-accroche{
			color: $blue;
			font-family: $font-regular;
			@include font-size(14);
			width: 100%;
			
			&.prog{
				@include font-size(15);

				@include mq($from: tablet) {
					@include font-size(14);
				}

				@include mq($from: desktop) {
					@include font-size(16);
				}
		
				@include mq($from: wide) {
					@include font-size(18);
				}
 			}

			@include mq($from: tablet) {
				@include font-size(12);
			}

			@include mq($from: desktop) {
				@include font-size(14);
			}
	
			@include mq($from: wide) {
				@include font-size(16);
			}
		}
	}

 	.img-entete{
 		display: none;

 		@include mq($from: tablet) {
 			display: block;
 			float: right;
 		}
 	}
 }

.contenu-societe,
.contenu-investissement-patrimonial,
.contenu-legal {
	position: relative;
	width: 100%;
	padding: 20px;

	@include mq($from: tablet) {
		width: 680px;
		margin: 20px auto;
		padding: 0;
	}
	
	.titre-1 {
		text-transform: uppercase;
		font-family: $font-medium;
		color: $blue;
		text-align: center;
		@include font-size(20);
		padding: 20px 0;

		@include mq($from: desktop) {
			@include font-size(22);
			padding: 30px 0;
		}
	}

	.titre-2 {
		font-family: $font-base;
		@include font-size(12);
		font-weight: 700;
		color: $black;
		padding-bottom: 5px;

		@include mq($from: desktop) {
			@include font-size(14);
		}
	}

	.text {
		font-family: $font-base;
		@include font-size(12);
		font-weight: 300;
		margin-bottom: 20px;
		color: $black;

		@include mq($from: desktop) {
			@include font-size(14);
		}

		a {
			color: $blue;
			border-bottom: 1px solid $color-1;
			font-weight: bold;

			.no-touch & {
				transition:color 0.2s ease-in-out, border-color 0.2s ease-in-out;

				&:hover, &:focus {
					border-color: transparent;
					color: darken($color-1, 10%);
				}
			}
		}

		&.no-mb {
			margin: 0;
			color: $white;
		}

		.bold{
			display: inline-block;
			width: 100%;
			padding-top: 20px;
			font-weight: 700; 
			color: $color-4;
		}

		.souligne{
			text-decoration: underline;
		}
	}

	.list{
		font-family: $font-base;
		@include font-size(12);
		font-weight: 300;
		margin-bottom: 20px;
		color: $black;

		@include mq($from: desktop) {
			@include font-size(14);
		}

		li {
			list-style-type: disc;
		}
	}
}

.metiers-illustration{
	position: relative;
	width: 100%;
	margin: 0 auto 20px;

	.img-ill{
		width: 100%;
	}
}

.separateur-griffe{
	position: relative;
	width: 100%;
	height: 38px;
	margin: 0 auto;
	background: transparent url('#{$image-path}ligne.png') repeat-x center left;

	@include retina {
		background-image: url('#{$image-path}ligne@2x.png');
		-webkit-background-size: 1px 1px;
		background-size: 1px 1px;
	}

	@include mq($from: tablet) {
		
		width: 575px;

		&.contact{
			width: 100%
		}
	}

    .griffe-centrale{
    	position: relative;
    	width: 50px;
    	height: 38px;
    	margin: 0 auto;
    	background: $white url('#{$image-path}F.jpg') no-repeat center center;

		@include retina {
			background-image: url('#{$image-path}F@2x.jpg');
			-webkit-background-size: 37px 38px;
			background-size: 37px 38px;
		}
    	
    }
}


/***
 *     /$$$$$$$  /$$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$$   /$$$$$$  /$$      /$$ /$$      /$$ /$$$$$$$$  /$$$$$$ 
 *    | $$__  $$| $$__  $$ /$$__  $$ /$$__  $$| $$__  $$ /$$__  $$| $$$    /$$$| $$$    /$$$| $$_____/ /$$__  $$
 *    | $$  \ $$| $$  \ $$| $$  \ $$| $$  \__/| $$  \ $$| $$  \ $$| $$$$  /$$$$| $$$$  /$$$$| $$      | $$  \__/
 *    | $$$$$$$/| $$$$$$$/| $$  | $$| $$ /$$$$| $$$$$$$/| $$$$$$$$| $$ $$/$$ $$| $$ $$/$$ $$| $$$$$   |  $$$$$$ 
 *    | $$____/ | $$__  $$| $$  | $$| $$|_  $$| $$__  $$| $$__  $$| $$  $$$| $$| $$  $$$| $$| $$__/    \____  $$
 *    | $$      | $$  \ $$| $$  | $$| $$  \ $$| $$  \ $$| $$  | $$| $$\  $ | $$| $$\  $ | $$| $$       /$$  \ $$
 *    | $$      | $$  | $$|  $$$$$$/|  $$$$$$/| $$  | $$| $$  | $$| $$ \/  | $$| $$ \/  | $$| $$$$$$$$|  $$$$$$/
 *    |__/      |__/  |__/ \______/  \______/ |__/  |__/|__/  |__/|__/     |__/|__/     |__/|________/ \______/ 
 *                                                                                                              
 *                                                                                                              
 *                                                                                                              
 */

// .contenu-programmes{
// 	margin-top: 20px;

// 	.bloc-programme{
// 		display: block;
// 		width: 100%;
// 		@include clearfix();
// 		margin-bottom: 10px;
// 		transition:background-color 0.3s ease-in-out;

// 		&:hover{
// 			background-color: $color-10;
// 		}

// 		@include mq($from: tablet) {
// 			width: 360px;
// 			float: left;
// 			margin-bottom: 20px;
// 			margin-right: 40px;

// 			&:nth-child(even){
// 				margin-right: 0;
// 			}

// 			&:hover .img .titre{
// 				opacity: 1;
// 			}
// 		}

// 		@include mq($from: desktop) {
// 			width: 460px;
// 		}

// 		@include mq($from: wide) {
// 			width: 580px;
// 		}

// 		.img{
// 			float: left;
// 			position: relative;
// 			width: 100%;
			
// 			.illustration{
// 				width: 100%;
// 			}

// 			.titre{
// 				opacity: 1;
// 				position: absolute;
// 				width: 60%;
// 				top: 40%;
// 				left: 20%;
// 				font-family: $font-light;
// 				@include font-size(15);
// 				text-transform: uppercase;
// 				text-align: center;
// 				color: $white;
// 				padding: 10px;
// 				background-color: rgba($black, 0.7);
// 				transition:opacity 0.3s ease-in-out;

// 				@include mq($from: tablet) {
// 					opacity: 0;
// 					width: 240px;
// 					top: 40%;
// 					left: 50%;
// 					margin-left: -120px;
// 					@include font-size(20);
// 				}

// 				@include mq($from: desktop) {
// 					width: 280px;
// 					margin-left: -140px;
// 					@include font-size(25);
// 				}

// 				@include mq($from: wide) {
// 					width: 340px;
// 					margin-left: -170px;
// 					@include font-size(30);
// 				}
// 			}
// 		}

// 		.text{
// 			float: left;
// 			padding: 10px;

// 			@include mq($from: wide) {
// 				width: 100%;
// 				padding: 20px;
// 			}

// 			.ville{
// 				font-family: $font-medium;
// 				@include font-size(12);
// 				text-transform: uppercase;
// 				color: $color-8;

// 				@include mq($from: tablet) {
// 					@include font-size(16);
// 				}

// 				@include mq($from: desktop) {
// 					@include font-size(20);
// 				}
// 			}

// 			.info-sup{
// 				font-family: $font-base;
// 				color: $color-8;
// 				padding: 5px 0;
// 				@include font-size(12);

// 				@include mq($from: tablet) {
// 					@include font-size(14);
// 				}
			
// 			}
// 		}
// 	}
// }

.contenu-slider-menu{
	position: relative;
	width: 100%;
}

.programme-event {
	display: block;
	padding: 10px;
	margin: 10px 5px 0 5px;

	background-color: $color-1;

	color: #fff;
	text-align: center;

	@include mq($from: tablet) {
		margin-top: 20px;
		padding: 20px;

		@include font-size(18);
	}
}

.programme-pricefrom {
	display: block;
	padding: 10px 0;
	text-align: center;
	font-family: $font-medium;
	@include font-size(20);
	color: $pink;

	@include mq($from: tablet) {
		text-align: left;
		@include font-size(16);
	}

	@include mq($from: desktop) {
		@include font-size(20);
	}
}

.menu-programme{
	position: relative;
	@include clearfix();
	z-index: 10;
	margin: 20px -5px 15px -5px;
	padding: 0 15px;

	@include mq($until: tablet) {
		max-width: 500px;
	}

	@include mq($from: tablet) {
		padding: 0 0 0 225px;
	}

	@include mq($from: desktop) {
		padding: 0 0 0 330px;
	}

	// @include mq($from: desktop) {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 100px;
	// 	background-color: rgba($color-7,0.8);
	// 	margin-bottom: 0;
	// }

	// @include mq($from: wide) {
	// 	left: 130px;
	// }
	// 
	ul {
		@include clearfix;
	}

	.list-prog {
		float: left;
		// width: 33.33%;
		width: 50%;

		padding: 0 5px;

		// @include mq($from: tablet) {
		// 	width: 25%;
		// }

		// @include mq($from: desktop) {
		// 	float: none;
		// 	width: inherit;
		// }

		.item-prog {
			display: block;
			border: solid 1px $blue;
			padding: 8px 0;
			text-transform: uppercase;
			font-family: $font-light;
			color: $blue;
			@include font-size(12);
			text-align: center;

			transition:color 0.3s ease-in-out, background-color 0.3s ease-in-out;

			&:hover, &.selected{
				color: $white;
				background-color: $blue;
			}
		}
	}
}

.contenu-programme{
 	position: relative;
 	margin-bottom: 20px;
 	@include clearfix();
}

.prog-info{
 	position: relative;
 	float: left;
 	width: 100%;
 	border: 1px solid $color-10;
 	padding: 15px;

 	@include mq($from: tablet) {
		width: 200px;
		margin-top: -49px;
 	}

 	@include mq($from: desktop) {
		width: 300px;
 	}

 	.img-info{
		width: 100%;
		img{
			/*width: 100%;*/
			display:block; 
			margin: 0 auto;
			height: 100%;
		}
 	}

 	.titre-info{
 		font-family: $font-light;
		@include font-size(18);
		text-transform: uppercase;
		color: $color-9;
		padding: 20px 0 0;
		margin-bottom: 0;
		text-align: center;

		@include mq($from: tablet) {
			text-align: left;
		}

		@include mq($from: desktop) {
			@include font-size(24);
		}
 	}

 	.coord-info{
 		font-family: $font-light;
		@include font-size(14);
		color: $color-8;
		padding: 10px 0;
		text-align: center;

		@include mq($from: tablet) {
			text-align: left;
		}

		@include mq($from: desktop) {
			@include font-size(18);
		}
 	}

 	hr{
 		border-color: $color-1;
 		width: 90px;

 		@include mq($until: tablet) {
			margin: 0 auto;
		}
 	}

 	.specificite-info{
 		margin: 10px 0 5px 0;
 		padding: 8px;
 		font-family: $font-light;
		@include font-size(12);
		color: $white;
		background-color: $pink;

		@include mq($from: tablet) {
			text-align: left;
		}

		@include mq($from: desktop) {
			@include font-size(14);
		}
 	}

 	.details-info{
 		font-family: $font-light;
		@include font-size(12);
		color: $color-4;
		padding: 5px 0 10px 0;
		text-align: center;

		@include mq($from: tablet) {
			text-align: left;
		}

		@include mq($from: desktop) {
			@include font-size(14);
		}
 	}

 	.telch-plaquette{
		@extend .btn-lien;
		background-color: $color-10;
		color: $color-8;
		text-decoration: underline;

		&:hover{
			background-color: darken($color-10, 10%);
		}
 	}

 	.site-programme{
 		@extend .btn-lien;
		background-color: $color-2;
		color: $white;

		&:hover{
			background-color: darken($color-2, 10%);
		}
 	}
 }

.prog-descr{
 	position: relative;
	float: left;
	width: 100%;
 	padding: 0 25px;

 	@include mq($from: tablet) {
		width: 560px;
 	}

 	@include mq($from: desktop) {
		width: 660px;
 	}

 	@include mq($from: wide) {
 		width: 900px;
 	}

 	.bloc-situation-programme{

 		@include mq($from: tablet) {
			float: left;
			width: 50%;
			margin-bottom: 10px;
		}
	}

	.titre-programme{
		font-family: $font-light;
		@include font-size(25);
		text-transform: uppercase;
		color: $blue;
		padding: 20px 0;

		@include mq($from: desktop) {
			@include font-size(30);
		}
	}

	.ss-titre-programme{
		font-family: $font-light;
		@include font-size(16);
		color: $blue;
		padding-bottom: 8px;
		background: transparent url('#{$image-path}trait-titre.png') no-repeat bottom left;
		clear: both;

		@include retina {
			background-image: url('#{$image-path}trait-titre@2x.png');
			-webkit-background-size: 42px 1px;
			background-size: 42px 1px;
		}

		@include mq($from: desktop) {
			@include font-size(20);
		}
	}

	.contenu-txt-programme{
		font-family: $font-base;
		font-weight: 300;
		@include font-size(12);
		color: $color-8;
		padding: 10px 0 20px;

		@include mq($from: desktop) {
			@include font-size(14);
		}
	}

	.plan-programme{
		position: relative;
		width: 100%;
		margin: 20px auto;
		img{
			width: 100%;
			height: 100%;
		}
	}

	.map-programme{
		position: relative;
		width: 100%;
		height: 300px;
		margin-top:20px;
	}

	.renaissance_txt{
		color: $blue;
	}

	.renaissance_resume {
		color: $blue;
		display:none;
	}
 }


.tva-reduite{
	color:$color-12 !important;
	display: block;
	*{
		color:$color-12 !important;
	}
}

/* 
####  ##    ##   ##     ##  ########    ######    ########   
 ##   ###   ##   ##     ##  ##         ##    ##      ##      
 ##   ####  ##   ##     ##  ##         ##            ##      
 ##   ## ## ##   ##     ##  ######      ######       ##      
 ##   ##  ####    ##   ##   ##               ##      ##      
 ##   ##   ###     ## ##    ##         ##    ##      ##      
####  ##    ##      ###     ########    ######       ##      
 */

.encadre {
	display: block;
	padding: 20px;

	background-color: $blue;
	color: #fff !important;

	@include mq($until: tablet) {
		margin: 0 -20px;
	}

	.titre-2 {
		color: $white;
	}
}

.simulation {
	display: block;
	margin: 20px auto;

	@include mq($from: tablet) {
		margin-top: 40px;
		text-align: center;
	}
}

.simulation__btn {
	display: block;
	padding: 20px;

	border: 1px solid $color-1;
	background-color: #fff;

	@include font-size(16);
	font-family: $font-medium;
	text-transform: uppercase;
	color: $color-1;
	text-align: center;

	@include mq($from: tablet) {
		display: inline-block;

		@include font-size(20);
	}

	&:before {
		display: inline-block;
		margin-right: 10px;
		position: relative;
		top: -1px;

		font-family: renaissance;
		content: "\e601";
		-webkit-font-smoothing: antialiased;

		@include mq($from: tablet) {
			top: -3px;
		}
	}

	.no-touch & {
		transition:background-color 0.2s ease-in-out, color 0.2s ease-in-out;

		&:hover, &:focus {
			background-color: $color-1;
			color: #fff;
		}
	}



}

/***
 *     /$$$$$$$  /$$$$$$$$ /$$$$$$$$ /$$$$$$$$ /$$$$$$$  /$$$$$$$$ /$$   /$$  /$$$$$$  /$$$$$$$$
 *    | $$__  $$| $$_____/| $$_____/| $$_____/| $$__  $$| $$_____/| $$$ | $$ /$$__  $$| $$_____/
 *    | $$  \ $$| $$      | $$      | $$      | $$  \ $$| $$      | $$$$| $$| $$  \__/| $$      
 *    | $$$$$$$/| $$$$$   | $$$$$   | $$$$$   | $$$$$$$/| $$$$$   | $$ $$ $$| $$      | $$$$$   
 *    | $$__  $$| $$__/   | $$__/   | $$__/   | $$__  $$| $$__/   | $$  $$$$| $$      | $$__/   
 *    | $$  \ $$| $$      | $$      | $$      | $$  \ $$| $$      | $$\  $$$| $$    $$| $$      
 *    | $$  | $$| $$$$$$$$| $$      | $$$$$$$$| $$  | $$| $$$$$$$$| $$ \  $$|  $$$$$$/| $$$$$$$$
 *    |__/  |__/|________/|__/      |________/|__/  |__/|________/|__/  \__/ \______/ |________/
 *                                                                                              
 *                                                                                              
 *                                                                                              
 */

 .contenu-reference{
 	@extend .contenu-programme;
 }

 .ref-info{
 	@extend .prog-info;

 	@include mq($from: tablet) {
		margin-top: 0;
 	}
 }

 .ref-descr{
 	@extend .prog-descr;
 }

 .titre-reference{
	@extend .titre-programme;
 }

.plan-reference{
	@extend .plan-programme;
}

 /***
 *      /$$$$$$   /$$$$$$  /$$   /$$ /$$$$$$$$  /$$$$$$   /$$$$$$  /$$$$$$$$
 *     /$$__  $$ /$$__  $$| $$$ | $$|__  $$__/ /$$__  $$ /$$__  $$|__  $$__/
 *    | $$  \__/| $$  \ $$| $$$$| $$   | $$   | $$  \ $$| $$  \__/   | $$   
 *    | $$      | $$  | $$| $$ $$ $$   | $$   | $$$$$$$$| $$         | $$   
 *    | $$      | $$  | $$| $$  $$$$   | $$   | $$__  $$| $$         | $$   
 *    | $$    $$| $$  | $$| $$\  $$$   | $$   | $$  | $$| $$    $$   | $$   
 *    |  $$$$$$/|  $$$$$$/| $$ \  $$   | $$   | $$  | $$|  $$$$$$/   | $$   
 *     \______/  \______/ |__/  \__/   |__/   |__/  |__/ \______/    |__/   
 *                                                                          
 *                                                                          
 *                                                                          
 */

 .map-contact{
 	position: relative;
 	width: 100%;
 	height: 300px;
 	margin:20px 0;
 }

 .part{
 	float: left;
	width: 100%;
	padding: 10px;

	@include mq($from: desktop) {
		padding: 15px;
	}
 }

 .form-contact{
 	position: relative;
 	width: 100%;
 	@include clearfix();
 	margin: 0 0 20px 0;
 	background : $blue;

 	.part-g {
 		@extend .part;

 		@include mq($from: tablet) {
			width: 30%;
 		}

 		@include mq($from: desktop) {
			width: 40%;
 		}
 	}

 	.part-d {
 		@extend .part;

 		@include mq($from: tablet) {
			width: 70%;
 		}

		@include mq($from: desktop) {
			width: 60%;
 		}

 	}
 }

 .form-cell{
 	display: block;
 	margin-bottom: 10px;

 	&:last-of-type{
 		margin-bottom: 0;
 	}
 }

 .input, .input-textarea {
 	@include input_mobile();
 	position: relative;
 	display: block;
 	width: 100%;
 	font-family: $font-base;
 	padding: 10px;
 	background-color: $white;
 	border: solid 1px $color-8;

 	&::placeholder{
 		color: $light-blue;
 	}
 }

.select {
	@include input_mobile();
 	width: 100%;
 	display: block;
 	padding: 10px;
 	position: relative;
 	color: $light-blue;

 	border: solid 1px $color-8;	
 	border-radius: 0;
 	background: $white url('#{$image-path}select.png') 98% center no-repeat;
 	
 	@include retina {
 		background-image: url('#{$image-path}select@2x.png');
 		background-size: 20px;
 	}

 	font-family: $font-base;
 	cursor: pointer;
}

.input-textarea {
 	height: 100px;

 	@include mq($from: tablet) {
 		height: 347px;
 	}
}

.btn-envoyer{
	display: block;
	width: 100%;
	padding: 10px;
	background-color: $light-blue;
	font-family: $font-medium;
	color: $white;
	/*text-transform: uppercase;*/
	text-align: center;
	text-decoration: underline;
	border: 1px solid $light-blue;

	transition:background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

	&:hover{
		background-color: $white;
		color: $blue;
		border-color: $white;
	}
}

.form-return, .success, .error{
	position: relative;
	margin: 0 auto;
	padding: 10px;
	font-family: $font-light;
	color: $black;
	text-transform: uppercase;
	text-align: center;
}


/***
 *     /$$       /$$$$$$  /$$$$$$  /$$$$$$$$ /$$$$$$$$
 *    | $$      |_  $$_/ /$$__  $$|__  $$__/| $$_____/
 *    | $$        | $$  | $$  \__/   | $$   | $$      
 *    | $$        | $$  |  $$$$$$    | $$   | $$$$$   
 *    | $$        | $$   \____  $$   | $$   | $$__/   
 *    | $$        | $$   /$$  \ $$   | $$   | $$      
 *    | $$$$$$$$ /$$$$$$|  $$$$$$/   | $$   | $$$$$$$$
 *    |________/|______/ \______/    |__/   |________/
 *                                                    
 *                                                    
 *                                                    
 */
 /***
 *                           /$$                        
 *                          | $$                        
 *      /$$$$$$   /$$$$$$$ /$$$$$$   /$$   /$$  /$$$$$$$
 *     |____  $$ /$$_____/|_  $$_/  | $$  | $$ /$$_____/
 *      /$$$$$$$| $$        | $$    | $$  | $$|  $$$$$$ 
 *     /$$__  $$| $$        | $$ /$$| $$  | $$ \____  $$
 *    |  $$$$$$$|  $$$$$$$  |  $$$$/|  $$$$$$/ /$$$$$$$/
 *     \_______/ \_______/   \___/   \______/ |_______/ 
 *                                                      
 *                                                      
 *                                                      
 */
 /***
 *                                                                            /$$$$$$ 
 *                                                                           /$$__  $$
 *      /$$$$$$   /$$$$$$   /$$$$$$   /$$$$$$             /$$$$$$   /$$$$$$ | $$  \__/
 *     /$$__  $$ /$$__  $$ /$$__  $$ /$$__  $$           /$$__  $$ /$$__  $$| $$$$    
 *    | $$  \ $$| $$  \__/| $$  \ $$| $$  \ $$          | $$  \__/| $$$$$$$$| $$_/    
 *    | $$  | $$| $$      | $$  | $$| $$  | $$          | $$      | $$_____/| $$      
 *    | $$$$$$$/| $$      |  $$$$$$/|  $$$$$$$ /$$      | $$      |  $$$$$$$| $$      
 *    | $$____/ |__/       \______/  \____  $$| $/      |__/       \_______/|__/      
 *    | $$                           /$$  \ $$|_/                                     
 *    | $$                          |  $$$$$$/                                        
 *    |__/                           \______/                                         
 */


 .contenu-liste{
 	position: relative;
	margin-top: 20px;

	.bloc{
		display: block;
		width: 100%;
		@include clearfix();
		margin-bottom: 20px;
		transition:background-color 0.3s ease-in-out;

		&:hover{
			background-color: $color-10;
		}

		@include mq($from: tablet) {
			width: 360px;
			float: left;
			margin-right: 40px;

			&:nth-child(even){
				margin-right: 0;
			}

			&:hover .img .titre{
				opacity: 1;
			}
		}

		@include mq($from: desktop) {
			width: 460px;
		}

		@include mq($from: wide) {
			width: 580px;
		}


		.img{
			float: left;
			position: relative;
			width: 100%;
			
			.illustration{
				width: 100%;
			}

			.titre{
				opacity: 1;
				position: absolute;
				width: 60%;
				top: 40%;
				left: 20%;
				font-family: $font-light;
				@include font-size(15);
				text-transform: uppercase;
				text-align: center;
				color: $white;
				padding: 10px;
				background-color: rgba($blue, 0.7);
				transition:opacity 0.3s ease-in-out;

				@include mq($from: tablet) {
					opacity: 0;
					width: 80%;
					top: 40%;
					left: 10%;
					@include font-size(20);
				}

				@include mq($from: desktop) {
					@include font-size(25);
				}

				@include mq($from: wide) {
					width: 80%;
					left: 10%;
					@include font-size(30);
				}
			}
		}

		.text{
			float: left;
			padding: 10px;

			@include mq($from: wide) {
				width: 100%;
				padding: 20px;
			}

			.info-1{
				font-family: $font-medium;
				@include font-size(12);
				text-transform: uppercase;
				color: $blue;

				@include mq($from: tablet) {
					@include font-size(16);
				}

				@include mq($from: desktop) {
					@include font-size(20);
				}
			}

			.info-2{
				font-family: $font-base;
				color: $color-8;
				padding: 10px 0;
				@include font-size(12);

				@include mq($from: tablet) {
					@include font-size(14);
				}
			}
		}
	}
}

/***
 *                                   /$$                       /$$     /$$                    
 *                                  |__/                      | $$    |__/                    
 *      /$$$$$$   /$$$$$$   /$$$$$$  /$$ /$$$$$$$   /$$$$$$  /$$$$$$   /$$  /$$$$$$  /$$$$$$$ 
 *     /$$__  $$ |____  $$ /$$__  $$| $$| $$__  $$ |____  $$|_  $$_/  | $$ /$$__  $$| $$__  $$
 *    | $$  \ $$  /$$$$$$$| $$  \ $$| $$| $$  \ $$  /$$$$$$$  | $$    | $$| $$  \ $$| $$  \ $$
 *    | $$  | $$ /$$__  $$| $$  | $$| $$| $$  | $$ /$$__  $$  | $$ /$$| $$| $$  | $$| $$  | $$
 *    | $$$$$$$/|  $$$$$$$|  $$$$$$$| $$| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$
 *    | $$____/  \_______/ \____  $$|__/|__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/
 *    | $$                 /$$  \ $$                                                          
 *    | $$                |  $$$$$$/                                                          
 *    |__/                 \______/                                                           
 */

 .paginate{
 	position: relative;
 	clear: both;
 	width: 100%;
 	margin-bottom: 20px;
 	padding: 0 10px;
 	@include clearfix();

 	@include mq($from: tablet) {
 		padding: 0;
 	}

 	.item{
 		display: inline-block;
 		float: left;
		margin-right: 5px;

 		&:last-of-type{
			margin-right: 0;
		}
 	}

 	.item-a{
 		display: block;
		padding: 8px;

		border: 1px solid $color-1;
		background-color: #fff;

		@include font-size(12);
		font-family: $font-medium;
		text-transform: uppercase;
		color: $color-1;

		transition:background-color 0.2s ease-in-out, color 0.2s ease-in-out;

		&:hover, &:focus, &.active {
			background-color: $color-1;
			color: #fff;
		}

		@include mq($from: tablet) {
			@include font-size(14);
		}
 	}
 }

/***
 *      /$$$$$$   /$$$$$$  /$$$$$$$$ /$$   /$$  /$$$$$$  /$$       /$$$$$$ /$$$$$$$$ /$$$$$$$$
 *     /$$__  $$ /$$__  $$|__  $$__/| $$  | $$ /$$__  $$| $$      |_  $$_/|__  $$__/| $$_____/
 *    | $$  \ $$| $$  \__/   | $$   | $$  | $$| $$  \ $$| $$        | $$     | $$   | $$      
 *    | $$$$$$$$| $$         | $$   | $$  | $$| $$$$$$$$| $$        | $$     | $$   | $$$$$   
 *    | $$__  $$| $$         | $$   | $$  | $$| $$__  $$| $$        | $$     | $$   | $$__/   
 *    | $$  | $$| $$    $$   | $$   | $$  | $$| $$  | $$| $$        | $$     | $$   | $$      
 *    | $$  | $$|  $$$$$$/   | $$   |  $$$$$$/| $$  | $$| $$$$$$$$ /$$$$$$   | $$   | $$$$$$$$
 *    |__/  |__/ \______/    |__/    \______/ |__/  |__/|________/|______/   |__/   |________/
 *                                                                                            
 *                                                                                            
 *                                                                                            
 */

 .contenu-actualite{
 	position: relative;
	width: 100%;
	padding: 20px;

	@include mq($from: tablet) {
		width: 600px;
		margin: 20px auto;
		padding: 0;
	}

	@include mq($from: desktop){
		width: 900px;
	}

	@include mq($from: wide){
		width: 1100px;
	}

	.img-actu{
		width: 100%;

		.illustration{
			width: 100%;
		}
	}

	.titre-actu{
		font-family: $font-light;
		@include font-size(20);
		text-transform: uppercase;
		color: $color-8;
		padding: 20px 0;
		text-align: center;

		@include mq($from: tablet) {
			@include font-size(25);
		}

		@include mq($from: desktop) {
			@include font-size(30);
		}
	}

	.date{
		font-family: $font-regular;
		@include font-size(14);
		text-transform: uppercase;
		color: $color-8;

		@include mq($from: desktop) {
			@include font-size(16);
		}
	}
 }

 .bloc-btn{
 	position: relative;
 	text-align: center;
 	margin-top: 20px;

 	.btn{
 		display: inline-block;
		padding: 10px;

		border: 1px solid $color-1;
		background-color: #fff;

		@include font-size(14);
		font-family: $font-medium;
		text-transform: uppercase;
		color: $color-1;
		text-align: center;
		transition:background-color 0.2s ease-in-out, color 0.2s ease-in-out;

		&:hover, &:focus {
			background-color: $color-1;
			color: #fff;
		}

		@include mq($from: tablet) {
			@include font-size(16);
		}
 	}
 }

/* 
########   ########   ########    ######     ######    
##     ##  ##     ##  ##         ##    ##   ##    ##   
##     ##  ##     ##  ##         ##         ##         
########   ########   ######      ######    ##         
##         ##   ##    ##               ##   ##         
##         ##    ##   ##         ##    ##   ##    ##   ###
##         ##     ##  ########    ######     ######    ###
*/
/////// DÉTAIL PROGRAMME ///////
.presc--detail-program {
	margin: 20px 0;
	width: 100% !important;

	@include mq($until: tablet) {
		padding: 0 10px;
	}

	@include mq($from: tablet) {
		padding: 0;
	}
	@include mq($from: desktop) {
		margin: 30px 0;
	}
	@include mq($from: wide) {
		margin: 40px 0;
	}

	.ss-titre-programme {
		text-transform: uppercase;
	}
}

.img-container {

	img {
		width: 100%;
		height: auto;
	}
}

.map-presc {
	position: relative;
	width: 100%;
	height: 200px;

	@include mq($from: tablet) {
		height: 100%;
	}
}

.presc--download-list {
	padding: 10px 0;
	margin: 10px 0;

	li {
		margin: 0 0 0.25em 0;

		@include mq($from: desktop) {
			margin: 0 0 0.4em 0;
		}
		@include mq($from: wide) {
			margin: 0 0 0.5em 0;
		}

		a {
			position: relative;
			color: $blue;
			@include font-size(13);
			padding-left:30px;

			transition: color 0.2s ease-in-out;

			@include mq($from: desktop) {
				@include font-size(14);
			}

			&:before{
				@extend .font-icon;
				content: "\e067";
				@include font-size(20);
				display: inline-block;
				position: absolute;
				top:50%;
				left:5px;
				margin:-10px 0 0 0;
			}
			
			.no-touch & {
				&:hover {
					color: $color-3;
				}
			}
		}
	}
}

.presc--img-list {

	li:not(:last-of-type){
		float: left;
		width: 60px;
		height: 60px;
		margin: 0 5px 5px 0;

		a {
			display: block;
			position: relative;
			height: 100%;
			width: 100%;
			transition: opacity 0.2s ease-in-out;

			img {
				width: 100%;
				height: auto;
				z-index: 1;
			}

			.mask-hover {
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: $blue;
				background-color: rgba($blue, 0.8);
				z-index: 5;
				opacity: 0;

				transition: opacity 0.2s ease-in-out;

				&:before {
					display: block;
					text-align: center;
					margin: 0 auto;
					padding: 0;
					@include font-size(23);
					line-height: 60px;
					color: $white;
				}
			}

			.no-touch & {
				&:hover {
					.mask-hover {
						opacity: 1;
					}
				}
			}
		}
	}

	li:last-of-type{
		clear: both;
		a{
			position: relative;
			padding-left:30px;
			&:before{
				@extend .font-icon;
				content: "\e067";
				@include font-size(20);
				display: inline-block;
				position: absolute;
				top:50%;
				left:5px;
				margin:-10px 0 0 0;
			}
		}
	}
}

.presc--links{
	a{
		position: relative;
		padding-left:30px;
		border: 1px solid $blue;
		.no-touch &:hover, .no-touch &:focus, .touch &:active {
			background-color: lighten($blue,10%);			
		}

		&.presc--links--denonciation{
			float:right;
			background-color: $pink;
			.no-touch &:hover, .no-touch &:focus, .touch &:active {
				background-color: darken($color-11,10%);	
				color: white;		
			}
		}

	}
	[class^="icon-"]{
		@include font-size(16);
		position: absolute;
		top:50%;
		left:5px;
		margin:-8px 0 0 0;
	}
}

/// Tableau ///
table.presc--tableau {
	margin-bottom: 20px;
	width: 100%;
	font-family: $font-light;
	font-weight: 900;

	@include mq($from: tablet) {
		margin-top: 20px;
	}

	tr {
		
		&:not(.typologie) {
			background-color: $color-7;
			background-color: lighten($blue, 45%);
			transition: background-color 0.2s ease-in-out;
			
			strong{
				color: $white;
			}
			
			.no-touch & {
				&:hover {
					// cursor: pointer;
					background-color: darken($white, 10%);
					color: #707173;
					.link-option, strong{
						color: #707173;
					}
				}
			}

			&:nth-of-type(2n) {
				background-color: $white;
				color: #707173;
				.link-option, strong{
					color: #707173;
				}
			}
		}

		&.typologie {
			background-color: $light-blue !important;
			/*background-color: lighten($blue, 15%) !important;*/
			color: $white;
		}

		&.no-lot td {
			@include font-size(14);
			background-color: $color-11 !important;
			color: $white;
		}

	}

	th, td {
		@include font-size(12);
		border: 1px solid #ddd;

		&.hide-mobile {
			@include mq($until: desktop) {
				display: none;
			}
		}

		&.show-tablet {
			@include mq($from: tablet) {
				display: table-cell;
			}
		}
	}

	th {
		background-color: $blue;
		color: #fff;
		padding: 7px 5px 8px 5px;
		font-weight: initial;
	}

	td {
		text-align: center;
		padding: 5px 6px;
		vertical-align: middle;

		&.align-left {
			text-align: left;
		}

		strong {
			font-weight: bold;
			color: $color-3;
			display: inline-block;
			min-width: 100px;
		}

		&.link-option--container {
			padding: 0;
		}

		.link-option {
			border: none;
			background: transparent;

			display: block;
			height: 100%;
			width: 100%;
			z-index: 10;
			color: $white;
			font-weight: bold;
			text-decoration:underline;

			transition: color 0.2s ease-in-out;

			.no-touch & {
				a:hover {
					color: darken($color-2, 20%);
				}
			}

			&.link-option--optionne{
				color:$color-1;
			}
			&.link-option--vendu{
				color:red;
			}

		}
	}
}

table.presc--tableau-lot {
	width: 100%;

	tr {
		width: 100%;
		background-color: $color-1;
		background-color: lighten($color-1, 5%);
		color: $white;

		&:nth-of-type(2n) {
			background-color: $white;
			color: $color-1;
		}

		&.link-download{
			text-align: center;
			a{
				transition: color 0.2s ease-in-out;
				&:before{ font-size:30px !important; }
				display: block;
				color:$color-2;
				text-align: center;
				.no-touch &:hover, .no-touch &:focus {
					color:$color-11;
				}
			}
		}

	}

	td {
		width: 100%;
		@include font-size(13);
		border: 1px solid #ddd;
		text-align: left;
		padding: 8px 10px 9px 10px;
		vertical-align: middle;

		strong {
			font-weight: bold;
			color: $color-3;
			display: inline-block;
			min-width: 100px;
		}
	}

	.link-option--container td {
		padding: 0;
	}

	.link-option {
		border: none;
		background: transparent;

		display: block;
		color: $white;
		font-weight: bold;
		height: 100%;
		width: 100%;
		padding: 8px 10px 9px 10px;
		background-color: $color-2;

		&.link-option--optionne{
			background-color:$color-1;
		}
		&.link-option--vendu{
			background-color:red;
		}

		transition: background-color 0.2s ease-in-out;

		.no-touch & {
			&:hover {
				background-color: darken($color-2, 10%);
			}
		}
	}
}

.btn-retour {
	position: relative;
	display: inline-block;
	padding: 8px 14px 9px 23px;
	margin-bottom: 20px;
	border: 1px solid #293581;
	color: #293581;
	text-transform: uppercase;

	transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

	@include mq($until: tablet) {
		margin: 0 0 20px 10px;
	}

	@include mq($from: desktop) {
		margin-bottom: 40px;
	}

	.no-touch & {
		&:hover {
			background-color: #293581;
			color: $white;
		}
	}

	&:before {
		position: absolute;
		left: 6px;
		top: 8px;
		@include font-size(15);
	}

	&.btn-retour--add-margin-top{
		margin:0;
		margin-top:20px;
	}
}

/// Modal 'Poser une option' ///
.md-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 80%;
	max-width: 580px;
	min-width: 300px;
	height: auto;
	z-index: 2000;
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);

	background-color: $white;
	padding: 40px 20px 20px 20px;

	@include mq($from: tablet) {
	width: 50%;		
	}

	textarea, input, select {
		@include input_mobile();
		@include font-size(13);
		width: 100%;
		padding: 10px;
		color: $color-3;
		margin-bottom: 20px;
		border: 1px solid $black;
	}
}

.btn-valid {
	display: inline-block;
	padding: 8px 20px;
	border: 1px solid $light-blue;
	color: $white;
	background-color: $light-blue;

	transition: background-color 0.2s ease-in-out;

	.no-touch & {
		&:hover {
			background-color: darken($light-blue, 10%);
		}
	}
}

.md-show {
	visibility: visible;
}

.md-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	top: 0;
	left: 0;
	z-index: 1000;
	opacity: 0;
	background: $color-3;
	background: rgba($color-3, 0.9);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.md-show ~ .md-overlay {
	opacity: 1;
	visibility: visible;
}

.md-close {
	border: none;
	background: transparent;
	box-shadow: none;
	position: absolute;
	top: 12px;
	right: 12px;
	@include font-size(18);
}

.md-content{

		color: $black;
		.error{
			color: $black;
		}
}

/***
 *     /$$$$$$$$  /$$$$$$   /$$$$$$  /$$$$$$$$ /$$$$$$$$ /$$$$$$$ 
 *    | $$_____/ /$$__  $$ /$$__  $$|__  $$__/| $$_____/| $$__  $$
 *    | $$      | $$  \ $$| $$  \ $$   | $$   | $$      | $$  \ $$
 *    | $$$$$   | $$  | $$| $$  | $$   | $$   | $$$$$   | $$$$$$$/
 *    | $$__/   | $$  | $$| $$  | $$   | $$   | $$__/   | $$__  $$
 *    | $$      | $$  | $$| $$  | $$   | $$   | $$      | $$  \ $$
 *    | $$      |  $$$$$$/|  $$$$$$/   | $$   | $$$$$$$$| $$  | $$
 *    |__/       \______/  \______/    |__/   |________/|__/  |__/
 *
 */

footer{
	.contain-logo{
		float:left;
		display:none;

		@include mq($from: desktop) {
			display: block;
		}  
	}
}

 .contain-coord-footer{
	position: relative;
	width: 100%;
	height: 55px;
	background: transparent url('#{$image-path}ligne.png') repeat-x center left;
	margin-bottom: 20px;
	padding: 0 25px 0 25px;

	@include retina {
		background-image: url('#{$image-path}ligne@2x.png');
		-webkit-background-size: 1px 2px;
		background-size: 1px 2px;
	}

	

	@include mq($from: tablet) {
		height: 70px;
		padding: 0 25px 0 50px;
	}

	.coord{
		float: left;
		width: 180px;
		background-color: $white;
		padding: 0 10px;

		@include mq($from: tablet) {
			width: 230px;
		}

		p{
			font-family: $font-medium;
			color: $pink;
			@include font-size(11);
			font-style: normal;

			@include mq($from: tablet) {
				@include font-size(14);
			}

			a{
				color: $blue;
				font-family: $font-medium;
				@include font-size(20);
				font-style: normal;

				@include mq($from: tablet) {
					@include font-size(30);
				}
			}
		}
	}

	.griffe{
		margin-top: 5px;
		float: right;
		width: 45px;
		height: 45px;
		background: transparent url('#{$image-path}F.jpg') no-repeat center center;

		@include retina {
			background-image: url('#{$image-path}F@2x.jpg');
			-webkit-background-size: 45px 45px;
			background-size: 45px 45px;
		}
		
		@include mq($from: tablet) {
			margin-top: 12px;
		}
	}
 }

.contain-menu-footer{
	@include clearfix;
	background-color: $blue;
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
	
	@include mq($from: tablet) {
		height: 155px;
		padding: 15px 60px;
	}
}

.nav-footer{
	position: relative;
	float: right;

	li {
		display: inline-block;
		float: left;

		span{
			display: inline-block;
			padding: 0 5px;
			color: $white;
		}

		
	}
}

.nav-footer,
.adresse-footer {
	a {
		font-family: $font-medium;
		@include font-size(12);
		color: $white;
		/*border-bottom: $color-1 solid 1px;*/
		transition:border-color 0.3s ease-in-out;

		&:hover, &.selected{
			border-bottom: $white solid 1px;
		}

		@include mq($from: tablet) {
			@include font-size(14);
		}
	}
}

.adresse-footer{
	position: relative;
	float: left; 
	padding-left: 30px;

	@include mq($until: tablet) {
		clear: both;
	}

	.logo-footer{
		width: 130px;
	}

	.adresse{
		font-family: $font-light;
		color: $white;
		@include font-size(12);
		padding-top: 20px;
		padding-bottom: 20px;

		a{
			line-height:26px;
		}

		@include mq($from: tablet) {
			@include font-size(14);
			padding-top: 40px;
		}
	}

}


/***
 *     /$$$$$$$  /$$        /$$$$$$  /$$   /$$        /$$$$$$  /$$$$$$ /$$$$$$$$ /$$$$$$$$
 *    | $$__  $$| $$       /$$__  $$| $$$ | $$       /$$__  $$|_  $$_/|__  $$__/| $$_____/
 *    | $$  \ $$| $$      | $$  \ $$| $$$$| $$      | $$  \__/  | $$     | $$   | $$      
 *    | $$$$$$$/| $$      | $$$$$$$$| $$ $$ $$      |  $$$$$$   | $$     | $$   | $$$$$   
 *    | $$____/ | $$      | $$__  $$| $$  $$$$       \____  $$  | $$     | $$   | $$__/   
 *    | $$      | $$      | $$  | $$| $$\  $$$       /$$  \ $$  | $$     | $$   | $$      
 *    | $$      | $$$$$$$$| $$  | $$| $$ \  $$      |  $$$$$$/ /$$$$$$   | $$   | $$$$$$$$
 *    |__/      |________/|__/  |__/|__/  \__/       \______/ |______/   |__/   |________/
 *                                                                                        
 *                                                                                        
 *                                                                                        
 */

.contenu-plan-site {
	position: relative;
	margin: 20px 0;
	@include clearfix();

	.liste-plan-site{
		padding: 0 20px;

		@include mq($from: tablet) {
			width: 700px;
			margin: 0 auto;
		}

		@include mq($from: desktop) {
			width: 800px;
		}

		@include mq($from: wide) {
			width: 1000px;
		}
	}

	.item-liste{
		float: left;
		width: 100%;
		padding-bottom: 20px;

		&:nth-of-type(odd){
			clear: both;
		}

		@include mq($from: tablet) {
			width: 50%;
		}		

		.niv-1{
			display: block;
			text-transform: uppercase;
			font-family: $font-regular;
			color: $blue;
			@include font-size(15);
			padding-bottom: 10px;

			transition:color 0.3s ease-in-out;

			&:hover{
				color: $color-4;
			}

				@include mq($from: desktop) {
					@include font-size(18);
				}
		}
		
		.sous-liste{
			padding-left: 10px;

			.sous-item{
				clear: both;
			}

			.fleche-ss-niv{
				display: block;
				float: left;
				color: $color-8;
				padding-right: 5px;
			}

			.niv-2{
				display: block;
				font-family: $font-regular;
				color: $color-8;
				@include font-size(13);

				transition:color 0.3s ease-in-out;

				&:hover{
					color: $color-4;
				}

				@include mq($from: desktop) {
					@include font-size(15);
				}
			}
		}
	}
}

/***
 *     /$$$$$$$$  /$$$$$$  /$$   /$$
 *    | $$_____/ /$$__  $$| $$  /$$/
 *    | $$      | $$  \__/| $$ /$$/ 
 *    | $$$$$   | $$      | $$$$$/  
 *    | $$__/   | $$      | $$  $$  
 *    | $$      | $$    $$| $$\  $$ 
 *    | $$      |  $$$$$$/| $$ \  $$
 *    |__/       \______/ |__/  \__/
 *                                  
 *                                  
 *                                  
 */

.editeur-text{
	clear: both;

	h2 {
		font-family: $font-light;
		@include font-size(16);
		color: $blue;
		padding-bottom: 8px;
		margin: 10px 0;
		background: transparent url('#{$image-path}trait-titre.png') no-repeat bottom left;
		clear: both;
		text-transform: uppercase;

		@include mq($from: desktop) {
			@include font-size(20);
		}
	}

	h3 {
		font-family: $font-base;
		@include font-size(12);
		font-weight: 700; 
		color: $color-4;
		padding: 10px 0;
		margin: 10px 0;
		
		@include mq($from: desktop) {
			@include font-size(14);
		}
	}

	p{
		font-family: $font-base;
		font-weight: 300;
		@include font-size(12);
		color: $color-8;
		padding: 10px 0;
		margin: 10px 0;

		@include mq($from: desktop) {
			@include font-size(14);
		}

		img{
			width: 100%;
			margin: 15px auto;
		}
	}

	ul{
		font-family: $font-base;
		@include font-size(12);
		font-weight: 300;
		margin-bottom: 20px;
		padding: 10px 15px;
		margin: 10px 0;
		color: $color-8;

		@include mq($from: desktop) {
			@include font-size(14);
		}

		li{
			list-style-type: disc;
		}
	}

	strong {
		font-weight: bold;
	}
}
