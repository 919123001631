.formError {
	position:absolute;
	top:300px; left:300px;
	width:150px;
	padding-bottom:15px;
	display:block;
	z-index:5000;
}
.formError .formErrorContent {
	text-align: left;
	width:100%; 
	background-color:$color-8;
	color:#fff;
	font-family:tahoma;
	font-size:10px;
	padding:2px;
	border-radius: 6px;
	/*-moz-border-radius: 6px;
	-webkit-border-radius: 6px;*/
}
.formError .formErrorArrow{
	position:absolute;
	bottom:0;left:20px;
	width:15px; height:15px;
	
}
.formError .formErrorArrow div{

	font-size:0px; 
}
.formError .formErrorArrow .line10{width:15px;height:1px; background:$color-8;margin:0 auto; font-size:0px; display:block;} 
.formError .formErrorArrow .line9{width:13px;height:1px; background:$color-8;margin:0 auto;display:block;} 
.formError .formErrorArrow .line8{width:11px;height:1px; background:$color-8;margin:0 auto;display:block;} 
.formError .formErrorArrow .line7{width:9px;height:1px; background:$color-8;margin:0 auto;display:block;} 
.formError .formErrorArrow .line6{width:7px;height:1px; background:$color-8;margin:0 auto;display:block;} 
.formError .formErrorArrow .line5{width:5px;height:1px; background:$color-8;margin:0 auto;display:block;} 
.formError .formErrorArrow .line4{width:3px;height:1px; background:$color-8;margin:0 auto;display:block;} 
.formError .formErrorArrow .line3{width:1px;height:1px; background:$color-8;margin:0 auto;display:block;} 