.hidden { display: none; }

.clear { clear: both; }

.align-left   { text-align: left; }
.align-center { text-align: center; }
.align-right  { text-align: right; }

.print-only{ display: none; }

.fluid-image {
	width: 100%;
	height: auto;
}

.absolute-child {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin vertical-align() {
	display: table;
	width: 100%;
	height: 100%;
}

@mixin vertical-align-inner() {
	display: table-cell;
	vertical-align: middle;
}

.vertical-align {
	@include vertical-align();
}

.vertical-align-inner {
	@include vertical-align-inner();
}

.to-tablet {
	@include mq($from: tablet) {
		display: none;
	}
}

.to-desktop {
	@include mq($from: desktop) {
		display: none;
	}
}

.from-tablet {
	@include mq($until: tablet) {
		display: none;
	}
}

.from-desktop {
	@include mq($until: desktop) {
		display: none;
	}
}