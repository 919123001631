/* 
 ######    ########   ####  ########   
##    ##   ##     ##   ##   ##     ##  
##         ##     ##   ##   ##     ##  
##   ####  ########    ##   ##     ##  
##    ##   ##   ##     ##   ##     ##  
##    ##   ##    ##    ##   ##     ##  
 ######    ##     ##  ####  ########   
 */

.row {
	display: block;
	@include clearfix;
	margin: -$grid-gutter-base;

	@include mq($from: tablet) {
		margin: -$grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		margin: -$grid-gutter-desktop;
	}

	@include mq($from: wide) {
		margin: -$grid-gutter-wide;
	}

	&.add-vertical-padding {
		@include mq($until: tablet) {
			padding-top: $grid-gutter-base;
			padding-bottom: $grid-gutter-base;
		}
	}
}

.col-1-2,
.col-2-2,
.col-1-3,
.col-2-3,
.col-3-3,
.col-1-4,
.col-2-4,
.col-3-4,
.col-4-4 {
	display: block;
	width: 100%;
	padding: $grid-gutter-base;
	float: left;

	@include mq($from: tablet) {
		padding: $grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		padding: $grid-gutter-desktop;
	}

	@include mq($from: wide) {
		padding: $grid-gutter-wide;
	}
}

.col-1-2 {
	@include mq($from: tablet) {
		width: percentage( 1/2 );
	}
}

.col-1-3 {
	@include mq($from: tablet) {
		width: percentage( 1/3 );
	}
}

.col-2-3 {
	@include mq($from: tablet) {
		width: percentage( 2/3 );
	}
}

.col-1-4 {
	@include mq($from: tablet) {
		width: percentage( 1/4 );
	}
}

.col-2-4 {
	@include mq($from: tablet) {
		width: percentage( 2/4 );
	}
}

.col-3-4 {
	@include mq($from: tablet) {
		width: percentage( 3/4 );
	}
}
