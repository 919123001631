html { font-size: 62.5%; }
$font-base:       "Open Sans", sans-serif;
$font-regular:    "dinregular";
$font-light:      "FrutigerLT-Light"; 
$font-medium:     "FrutigerLT-Bold";
$font-black:      "FrutigerLT-Bold";

$image-path: '/img/';

$font-size: 14;

$color-bg:         #fff;
$color-1:          #fff;
$color-2:          #7da7d9;
$color-3:          #707173;
$color-4:          #333333;
$color-5:          #545454;
$color-6:          #e6e1dc;
$color-7:          #f5f1ec;
$color-8:          #58585a;
$color-9:          #9a8d7f;
$color-10:         #e8e8e8;
$color-11:         #d8b16e;
$color-12:         #63a885;


$color-text:       $color-1;
$color-text-light: $color-2;
$color-link: red;
$color-link-hover: blue;
$color-border: #f0f0f0;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$white: #fff;
$black: #000;
$grey: #bcc5d9;
$blue: #293581;
$pink: #ea7865;
$light-blue: #4b5595;

$grid-gutter-base:    10px / 2;
$grid-gutter-tablet:  20px / 2;
$grid-gutter-desktop: 30px / 2;
$grid-gutter-wide:    40px / 2;
