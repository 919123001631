
@font-face {
    font-family: 'dinregular';
    src: url('../fonts/ff_din_regular-webfont.eot');
    src: url('../fonts/ff_din_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ff_din_regular-webfont.woff2') format('woff2'),
         url('../fonts/ff_din_regular-webfont.woff') format('woff'),
         url('../fonts/ff_din_regular-webfont.ttf') format('truetype'),
         url('../fonts/ff_din_regular-webfont.svg#dinregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'din_blackregular';
    src: url('../fonts/ff_din_black-webfont.eot');
    src: url('../fonts/ff_din_black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ff_din_black-webfont.woff2') format('woff2'),
         url('../fonts/ff_din_black-webfont.woff') format('woff'),
         url('../fonts/ff_din_black-webfont.ttf') format('truetype'),
         url('../fonts/ff_din_black-webfont.svg#din_blackregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'din_lightregular';
    src: url('../fonts/ff_din_light-webfont.eot');
    src: url('../fonts/ff_din_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ff_din_light-webfont.woff2') format('woff2'),
         url('../fonts/ff_din_light-webfont.woff') format('woff'),
         url('../fonts/ff_din_light-webfont.ttf') format('truetype'),
         url('../fonts/ff_din_light-webfont.svg#din_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'din_alternatemedium';
    src: url('../fonts/_ff_din_medium_alternate-webfont.eot');
    src: url('../fonts/_ff_din_medium_alternate-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/_ff_din_medium_alternate-webfont.woff2') format('woff2'),
         url('../fonts/_ff_din_medium_alternate-webfont.woff') format('woff'),
         url('../fonts/_ff_din_medium_alternate-webfont.ttf') format('truetype'),
         url('../fonts/_ff_din_medium_alternate-webfont.svg#din_alternateregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'renaissance';
    src:    url('../fonts/renaissance.eot?lbccmj');
    src:    url('../fonts/renaissance.eot?lbccmj#iefix') format('embedded-opentype'),
        url('../fonts/renaissance.ttf?lbccmj') format('truetype'),
        url('../fonts/renaissance.woff?lbccmj') format('woff'),
        url('../fonts/renaissance.svg?lbccmj#renaissance') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'FrutigerLT-Bold';
    src:    url('../fonts/FrutigerLT-Bold.eot?lbccmj');
    src:    url('../fonts/FrutigerLT-Bold.eot?lbccmj#iefix') format('embedded-opentype'),
        url('../fonts/FrutigerLT-Bold.ttf?lbccmj') format('truetype'),
        url('../fonts/FrutigerLT-Bold.woff?lbccmj') format('woff'),
        url('../fonts/FrutigerLT-Bold.svg?lbccmj#FrutigerLT-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'FrutigerLT-Light';
    src:    url('../fonts/FrutigerLT-Light.eot?lbccmj');
    src:    url('../fonts/FrutigerLT-Light.eot?lbccmj#iefix') format('embedded-opentype'),
        url('../fonts/FrutigerLT-Light.ttf?lbccmj') format('truetype'),
        url('../fonts/FrutigerLT-Light.woff?lbccmj') format('woff'),
        url('../fonts/FrutigerLT-Light.svg?lbccmj#FrutigerLT-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .font-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'renaissance' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-link:before {
    content: "\e005";
}
.icon-lock:before {
    content: "\e007";
}
.icon-cloud-download:before {
    content: "\e067";
}
.icon-print:before {
    content: "\e900";
}
.icon-keyboard-arrow-down:before {
    content: "\e602";
}
.icon-keyboard-arrow-left:before {
    content: "\e604";
}
.icon-keyboard-arrow-right:before {
    content: "\e600";
}
.icon-tune:before {
    content: "\e601";
}
.icon-close:before {
    content: "\e603";
}